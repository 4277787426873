@import '../../../../assets/scss/partials/variables';


.added-lab-test-section {
    height: 23vh;
    overflow-y: scroll;

    .individual-lab-test {
        small {
            font-size: 13px;
        }

        .icon {
            cursor: pointer;
            top: 15%;
            font-size: 10px !important;
            padding-right: 10px;

            &:hover {
                color: red;
            }
        }
    }

    input {
        font-size: 12px !important;
        margin-bottom: 6px;
        outline: none;
        width: 100%;
        border: .5px solid rgba(0, 0, 0, 0.699);
        border-radius: 3px;
    }
}