@import '../../../../assets/scss/partials/variables';

.file-upload {
    input {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 13px;
        width: 100%;
        padding-left: 4px;
    }

    textarea {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 12px;
        width: 100%;
        padding: 0px 4px;
    }

    select {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 12px;
        width: 100%;
        padding: 0px 4px;
    }

    .submit-button {
        font-size: 13px;
        padding: 0px 10px;
        height: 20px;
        border-radius: 2px;
    }

    .cancel-button {
        background: $color__main;
        color: white;
        font-size: 13px;
        padding: 0px 10px;
        margin-left: 4px;
        border-radius: 2px;
    }
}