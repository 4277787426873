.rich-text {
    label {
        margin-top: 4px;
        padding: 0px;
        margin-bottom: 0px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.631);
    }

    .tox {
        background-color: red !important;
        box-shadow: none !important;
        border-radius: 0px;

        .tox-editor-header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .tox-toolbar-overlord{
            display: flex;
            border: none !important;
        }
        @media screen and (max-width:480px) {
            .tox-toolbar-overlord{
                display: block;
                // border: none !important;
            }
        }

        .tox-menubar {
            font-size: 10px !important;
        }

        .tox-statusbar {
            display: none !important;
        }

        .tox-toolbar {
            padding-left: 10px !important;
            background:none !important;
            box-shadow: none !important;
            border-right: .1px solid rgba(0, 0, 0, 0.246);
            padding-right: 5px !important;
            button {

                font-size: 12px !important;
                padding: 0px !important;
                margin: 0px;
            }
        }
        @media screen and (max-width:480px) {
            .tox-toolbar {
                // padding-left: 10px !important;
                // background:none !important;
                // box-shadow: none !important;
                // border-right: .1px solid rgba(0, 0, 0, 0.246);
                // padding-right: 5px !important;
                button {
                    font-size: 12px !important;
                    padding: 0px !important;
                    margin: 0px;
                }
            }
        }


        .tox-icon {
            width: 11px !important;
        }

        svg {
            scale: .7;
        }

        .tox-toolbar__group {
            padding: 0px !important;
        }


    }
}