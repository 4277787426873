.sms-portal{
    margin-left: 71px !important;
    display: flex;
    .name-section {
        width: 20%;
        border-right: 1px solid rgba(0, 0, 0, 0.185);
        height: 100vh;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 5px 5px 10px;
        border-radius: 6px;
        height: 95vh;
        overflow-y: scroll
    }

    .form-section {
        width: 45%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 2px 2px 2px;
        border-radius: 6px;
        padding: 4px;
        height: 95vh;
        overflow-y: scroll;
        label{
            font-size: 14px;
        }
        textArea{
            outline: none !important;
            font-size: 13px;
        }
        .content{
            p{
                font-size: 13px;
                font-weight: 500;
                color: red;
                margin: 4px;
            }
        }
    }
    .template-section {
        width: 35%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 2px 2px 2px;
        border-radius: 6px;
        padding: 4px;
        height: 95vh;
        overflow-y: scroll;
    }
}

