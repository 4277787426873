.prescription-sent {
    .header {
        background-color: #13AC81;
        // text-align: center;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        height: 25px;
        margin-bottom: 20px;
        .title {
            font-size: 14px;
            font-weight: 600;
            padding: 2px 4px;
            color: white;
        }
    }

    input {
        font-size: 14px;
        height: 20px !important;
        padding: 2px 1px 2px 5px;
    }

    .submit-btn {
        background-color: #13AC81;
        color: white;
        font-size: 13px;
        height: 20px;
    }
}