@import "../../assets/scss/partials/variables";

.side-bar-color {
    box-shadow: 0 3px 12px 1px rgba(7, 82, 61, .15) !important;
    background-color: #F5F6FA !important;
}


.nav-link {
    color: #3c4d62 !important;

    &:hover {
        color: $color__logo !important;
    }

}

.active {
    color: $color__logo !important;
    background-color: #F5F6FA !important;
}

// .custom-nav-link{
//     color: #3c4d62 ;
//     &:hover{
//         color: $color__logo !important;
//     }
// }
.profile {
    position: absolute;
    top: 85%;
    margin-left: 4px;
    color: aqua;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    overflow: hidden;
    // border: 2px solid $color__logo;
    cursor: pointer;
    background-color: $color__logo;
    color: white;

    &:hover {
        animation: breathe 2s ease-in-out infinite;
    }

    @keyframes breathe {
        0% {
            box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
        }

        70% {
            box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    .single-letter-profile-name {
        margin-left: 30%;
        margin-top: 14% !important;
        font-weight: 800;
        font-size: 21px;
    }

    .double-letter-profile-name {
        margin-left: 18%;
        margin-top: 18% !important;
        font-weight: 800;
        font-size: 18px;
    }
}

.disable-btn {
    pointer-events: none;
}