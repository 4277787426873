.filter-section {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 0px 0px 5px 10px;
    border-radius: 6px;

    .heading {
        background-color: #0fac81;
        color: white;
        font-weight: 600;
        border-radius: 6px 6px 0px 0px;
        font-size: 13px;

        img {
            width: 17px;
        }
    }

    .date-filter {
        input {
            padding: 0px 5px !important;
            font-size: 12px !important;
            font-weight: 500;
            outline: none !important;
            color: #0fac81 !important;
            i{
                color: #0fac81 !important;
            }
        }
        label{
            font-size: 13px;
            padding-left: 5px;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .title {
        font-weight: 600;
        font-size: 14px;
    }

    .rest{
        &:hover{
            color: #0fac81;
            cursor: pointer;
        }
    }
}