@import "../../assets/scss/partials/variables";

.registration-container {
    height: 100vh;
    background-color: #e5e9f2;
    overflow: scroll;

    .slider-container {
        margin-top: 5%;

        .carousel-img {
            height: 700px;
            background-color: #e5e9f2;

            img {
                height: 550px;
                width: 800px;
                margin: auto;
            }
        }

        .carousel-font {
            color: black;
        }
    }

    @media screen and (max-width: 479px) {
        .slider-container {
            display: none;
        }
    }

    .login-form {
        background-color: white;

        input {
            border: .1px solid rgb(119, 113, 113);

            &:hover {
                border: .1px solid $color__loginBUtton;
            }

            &:focus {
                border: .1px solid $color__loginBUtton;
            }
        }

        .label {
            font-weight: 500;
            font-size: 14px;
        }

        .eye-icon {
            cursor: pointer;
            position: absolute;
            z-index: 12;
            right: 10px;
            top: 2px;
            font-weight: 400;
        }

        .login-form-container {
            margin: 4% 20% 0px;

            @media screen and (max-width: 479px) {
                margin: 4% 5% 0px;
            }

            input {
                font-size: 14px;
                height: 27px;
            }

            select {
                font-size: 14px;
                padding-left: 6px;
                padding-right: 12px;
                height: 27px;
                width: 100%;
                outline: none;
            }

        }

        .submit-button {
            background-color: $color__loginBUtton;
            color: white;
            font-weight: 700;
        }

        .login-icon {
            background-color: $color__loginBUtton;
        }

        .login-logo {
            margin-top: 25%;
            margin-bottom: 15%;
        }

        .login-form-footer {
            margin-top: 2%;
        }
    }

    @media screen and (max-width: 479px) {
        .slider-container {
            display: none;
        }
    }
}