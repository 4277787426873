@import "../../assets/scss/partials/variables";

.template-section {
    .name-section {
        width: 15%;
        border-right: 1px solid rgba(0, 0, 0, 0.185);
        height: 100vh;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 5px 5px 10px;
        border-radius: 6px;
        height: 99vh;
    }

    .prescription-section {
        width: 85%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 2px 2px 2px;
        border-radius: 6px;
        padding: 4px;
        height: 99vh;
    }

}

