@import "../../assets/scss/partials/variables";

.patient-container {
    height: 42vh;
    overflow: hidden;
    .info-section {
        p{
            font-size: 12px;
            margin: 4px;
            padding-left: 4px;
        }
        
    }
    button {
        margin-top: 0%;
        font-size: 12px;
        background-color: $color__main;
        color: white;
        padding: 0px 10px;
        border-radius: 1px;
        &:hover {
            background-color: $color__main;
            color: white;
        }
    }
}