@import "../../../assets/scss/partials/variables";

.prescription-full-body {
    overflow-y: scroll !important;
    height: 90vh;
    .top-section {
        display: flex;

        .compliant-section {
            width: 30%;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.463);
        }

        .medicine-section {
            width: 70%;
            min-height: calc(60vh - 35px);
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.463);
            border-left: 0.5px solid rgba(0, 0, 0, 0.463);
        }
    }

    .labTest-advice-section {
        min-height: calc(40vh - 38.4px);
        display: grid;
        grid-template-columns: 1fr 1fr;

        .lab-test {
            border-right: 0.5px solid rgba(0, 0, 0, 0.463);
        }

        small {
            font-size: 13px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            padding: 0px 4px;
            margin: 0px;
        }

        border-bottom: 0.5px solid rgba(0, 0, 0, 0.463);
    }

    p {
        font-size: 14px;
        font-weight: 500;
        padding: 0px 4px;
        margin: 0px;
    }

    .title {
        background-color: #0eac81;
        color: white;
    }
}
