@import "../../../assets/scss/partials/variables";

.vital-sign-details-section {
    .vital-sign-details-header {
        border-bottom: .5px solid rgba(0, 0, 0, 0.223);
        width: 100%;
        margin-top: 0px;
        padding: 0px 8px 4px 8px;

        .title {
            padding: 0px 10px 0px 10px !important;
            margin: 0px 0px 0px 0px !important;
            color: $color__logo;
        }
    }

    .details-card {
        table{
            thead{
                tr{
                    th{
                        font-size: 14px;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        font-size: 13px;
                    }
                }
            }
        }
      

    }

}