@import "../../../assets/scss/partials/variables";

.other-health-section {
    padding: 20px 20px;
    textarea {
        font-size: 13px;
        color: black;
        outline: none !important;
        border: .5px solid $secondary__color;
        &:hover{
            color: black;
            border: .5px solid $color__main;
        }
        &:focus{
            color: black;
            border: .5px solid $color__main;
        }
    }
    .submit-button {
        font-size: 12px;
        font-weight: 600;
        padding: 0;
        width: 120px;
    }

    table{
        margin-top: 30px;
        thead{
            tr{
                th{
                    font-size: 14px;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 13px;
                }
            }
        }
    }
}