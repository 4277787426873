.template-diagnosis {
    padding: 2px;

    textarea {
        font-size: 12px;
        padding: 3px 4px !important;
        color: black !important;
        background-color: white;

        &:focus {
            color: black !important;
        }
    }

    .submit-button {
        font-size: 13px;
    }
}