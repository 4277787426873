.full-prescription {
    padding: 0px 20px 0px 20px;
    font-size: 1rem;

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        display: none;
        border-radius: 10px;
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
}

.prescription-body {
    table {
        thead {
            tr {
                th {
                    border: 1px solid rgb(2, 2, 2) !important;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid rgb(2, 2, 2) !important;
                }
            }
        }
    }
}

.patientInfo {
    p {
        padding: 0px;
        margin: 0px;
        font-weight: 500;

        span {
            font-weight: 600 !important;
        }
    }

    tr td {
        padding: 2px !important;
        margin: 2px !important;
    }

    table {
        border: 1px solid rgb(2, 2, 2) !important;

        thead {
            tr {
                th {
                    border: 1px solid rgb(2, 2, 2) !important;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid rgb(2, 2, 2) !important;
                }
            }
        }
    }
}

.prescription-header {
    p {
        font-size: 15px;
        padding: 0;
        margin: 0;
        font-weight: 400;

        // text-align: end;
        span {
            font-weight: 600 !important;
        }
    }

    table {
        border: none !important;
        background-color: transparent !important;

        tbody {
            tr {
                td {
                    border: none !important;
                }
            }
        }
    }
}

.prescription-footer {
    margin-top: 20px;

    div {
        p {
            margin: 0 !important;
            padding: 0 !important;
            line-height: 1.3 !important;
        }

        table {
            border: none !important;

            tbody {
                tr {
                    td {
                        border: none !important;
                    }
                }
            }
        }
    }
}

.vision {
    table {
        thead {
            tr {
                th {
                    font-size: 16px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 16px;
                }
            }
        }
    }
}

.title {
    // font-size: 15px;
    font-weight: 600;
}

.option {
    // font-size: 15px;
}
.care-plan {
    .inner-html {
        p,
        h1,
        h2,
        h3,
        h4,
        h5 {
            padding: 0px !important;
            margin: 0px !important;
        }
    }
}

.display-hide {
    display: block;
}

@media screen and (max-width: 480px) {
    .display-hide {
        display: none !important;
    }
}

@media screen and (min-width: 481px) {
    .display-hide-lg {
        display: none !important;
    }
}
