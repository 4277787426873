@import "../../../assets/scss/partials/variables";

.prescription-full-body {
    overflow-y: scroll;

    .medicine-section {
        min-height: calc(60vh - 35px);
        border-bottom: .5px solid rgba(0, 0, 0, 0.463);
    }

    .labTest-advice-section-1 {
        min-height: calc(40vh - 38.4px);

        .lab-test {
            border-right: .5px solid rgba(0, 0, 0, 0.463);
            border-bottom: .5px solid rgba(0, 0, 0, 0.463);
        }

        small {
            font-size: 13px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            padding: 0px 4px;
            margin: 0px;
        }

        border-bottom: .5px solid rgba(0, 0, 0, 0.463);

        .next-visit {
            input {
                width: 100%;
                font-size: 13px;
                outline: none;
                border: .1px solid rgba(57, 57, 57, 0.528);
            }
        }
    }


}