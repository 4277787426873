@import '../../../../assets/scss/partials/variables';

.advice-section {
    .added-advice-section {
        height: 23vh;
        overflow-y: scroll;

        .individual-advice {
            small {
                font-size: 14px !important;
            }

            .icon {
                cursor: pointer;
                top: 15%;
                font-size: 10px !important;
                padding-right: 10px;

                &:hover {
                    color: red;
                }
            }
        }

        textarea {
            font-size: 13px;
            width: 100%;
            outline: none;
            padding: 4px !important;
            color: black;
        }


    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        display: block !important;
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: rgb(255, 255, 255);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #088aaa;
        border-radius: 10px;
    }
}