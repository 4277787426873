@import "../../assets/scss/partials/variables";

.staff-create-form-section {
    background-color: white;
    display: flex;
    padding: 0px 7px 10px 7px; 

    .input-section {
        width: 100;
        padding: 10px 5px 10px 5px;
    }

    .submit-button-position {
        margin-top: 60px;
        font-size: 13px;
        width: 30% !important;

    }

    input {
        font-size: 14px;
        // height: 10px;
        padding: 2px 1px 2px 5px;
    }

    .select-input {
        // height: 10px !important;
        font-size: 14px;
        padding: 0px;
        border-radius: 4px;
        outline: none;
        border: .5px solid rgba(45, 44, 44, 0.35);
        cursor: auto !important;

        &:hover {
            border: .5px solid $color__logo;

        }

        &:focus {
            border: .5px solid $color__logo;
        }

    }

    svg {
        margin: -3px;
    }

}