@import "../../assets/scss/partials/variables";

.login-container {
    height: 100vh;
    background-color: #e5e9f2;

    .slider-container {
        margin-top: 5%;

        .carousel-img {
            height: 700px;
            background-color: #e5e9f2;

            img {
                height: 550px;
                width: 800px;
                margin: auto;
            }
        }

        .carousel-font {
            color: black;
        }
    }

    @media screen and (max-width: 479px) {
        .slider-container {
            display: none;
        }
    }

    .login-form {
        background-color: white;

        input {
            border: .1px solid rgb(119, 113, 113);

            &:hover {
                border: .1px solid $color__loginBUtton;
            }

            &:focus {
                border: .1px solid $color__loginBUtton;
            }
        }

        .label {
            font-weight: 500;
            font-size: 14px;
        }

        .eye-icon {
            cursor: pointer;
            position: absolute;
            z-index: 12;
            right: 10px;
            top: 8px;
            font-weight: 400;
        }

        .login-form-container {
            margin: 25% 20% 0px;

            @media screen and (max-width: 479px) {
                margin: 25% 5% 0px;
            }
        }

        .submit-button {
            background-color: $color__loginBUtton;
            color: white;
            font-weight: 700;
        }

        .login-icon {
            background-color: $color__loginBUtton;
        }

        .login-logo {
            margin-top: 25%;
            margin-bottom: 15%;
        }

        .login-form-footer {
            margin-top: 34%;
        }
    }

}