@import "../../../../../assets/scss/partials/variables";

.medicine-sig-from-section {
    label {
        font-size: 13px;
        padding: 0px;
        margin: 0px;
    }

    .header {
        p {
            font-size: 13px;
        }

        span {
            color: $secondary__color;
        }
    }

    input {
        font-size: 12px;
        padding: 0px 4px;
        height: 20px !important;
        margin: 3px 5px !important;
    }

    textarea {
        font-size: 12px;
        padding: 0px 4px;
        min-height: 40px !important;
    }

    select {
        font-size: 12px;
        padding: 0px 4px;
        height: 20px !important;
        margin: 3px 5px !important;
        outline: none;
        border: .5px solid $secondary__color;
        border-radius: 2px;
    }

    .individual-input {
        padding: 0px !important;
        margin: 0px !important;

        p {
            font-size: 13px;
            padding-top: 3px;
        }

        label {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0px;
            min-width: 100px;
            max-width: 100px;
        }
    }

    .save-button {
        font-size: 13px;
        background-color: $color__main;
        color: white;
        font-weight: 600;
        padding: 0px 40px;
        height: 22px;

        .spinner-border {
            width: 11px;
            height: 11px;
            margin-top: 4px;
            margin-left: 5px;
        }
    }

    .close-button {
        font-size: 13px;
        background-color: $color__main;
        color: white;
        font-weight: 600;
        padding: 0px 10px;
    }

    .save-medicine {
        justify-content: center;

        label {
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .rotate-180 {
        transform: rotate(180deg);
    }

    .cursor {
        cursor: pointer;
        color: $color__main !important;
    }


}