.patient-name-section {
    .search-bar {
        font-size: 13px !important;
        width: 100% !important;
        outline: none;
    }
    .name-section-list {
        height: 90vh;
        overflow-y: scroll;
        .individual-name {
            // width: 100%;
            color: black;
            font-size: 13px;
            padding-left: 6px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.192);
            &:hover {
                background-color: #12ab81;
                color: white;
                // cursor: pointer;
            }

            .name {
                &:hover {
                    // background-color: $color__main;
                    color: white;
                    cursor: pointer;
                }
            }
            .delete {
                &:hover {
                    // background-color: $color__main;
                    color: red;
                    cursor: pointer;
                }
            }
            .update {
                &:hover {
                    // background-color: $color__main;
                    color: rgb(0, 255, 64);
                    cursor: pointer;
                }
            }
        }
    }
    .active-option {
        background-color: #17a980;
        color: white;
        font-size: 13px
    }

    .appointment-list-section{
        overflow-y: scroll;
        height: 90vh;

        .date-filter {
            input {
                padding: 0px 5px !important;
                font-size: 12px !important;
                font-weight: 500;
                outline: none !important;
                color: #0fac81 !important;
                i{
                    color: #0fac81 !important;
                }
            }
            label{
                font-size: 13px;
                padding-left: 5px;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .individual-name {
            // width: 100%;
            color: black;
            font-size: 13px;
            padding-left: 6px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.192);
            &:hover {
                background-color: #12ab81;
                color: white;
                // cursor: pointer;
            }

            .name {
                &:hover {
                    // background-color: $color__main;
                    color: white;
                    cursor: pointer;
                }
            }
            .delete {
                &:hover {
                    // background-color: $color__main;
                    color: red;
                    cursor: pointer;
                }
            }
            .update {
                &:hover {
                    // background-color: $color__main;
                    color: rgb(0, 255, 64);
                    cursor: pointer;
                }
            }
        }
    }
}

