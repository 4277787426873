.profile-page {
    display: flex;

    .menu {
        width: 17%;
        background-color: #F5F6FA;
        border-right: 1px solid #e5e9f2;
    }

    .main-content {
        width: 83%;
        overflow-y: auto;
        height: 100vh;
    }

    .out-line {
        border: 1px solid #0eac81 !important;
    }

    .save-button {
        background-color: #0EAC81;
        font-size: 12px !important;
        height: 20px !important;
        color: white;
        padding: 0px;
        font-weight: 600;
        width: 10% !important;
    }

    label {
        font-size: 14px;
    }

    select {
        font-size: 14px;
    }
    input{
        font-size: 14px;
        height: 22px;
    }
}