.vision-history {
    table {
        thead {
            tr {
                th {
                    font-size: 13px
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 13px
                }
            }
        }
    }

    p{
        font-size: 13px;
    }

}

.vision-print {
    table {
        thead {
            tr {
                th {
                    font-size: 15px
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 15px
                }
            }
        }
    }

    p{
        font-size: 15px;
    }

}

