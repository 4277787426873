@import '../../../../assets/scss/partials/variables';

.prescription-medicine-section {
    display: flex;

    input {
        outline: none;
    }

    .prescribed-medicine {
        width: 65%;

        .templates {
            p {
                font-size: 13px;
            }
        }

        table {
            thead {
                tr {
                    th {
                        font-size: 13px;
                    }
                }
            }

            tbody {
                tr {
                    position: relative;

                    td {
                        font-size: 13px;
                    }

                    textArea {
                        font-size: 12px;
                        width: 100%;
                        padding: 1px 6px;
                        margin: 0px;
                        color: black;
                    }

                    input {
                        font-size: 11px;
                        padding: 0px 1px;
                        margin: 0px;
                        color: black;
                    }

                    .icon {
                        position: absolute;
                        cursor: pointer;
                        top: 30%;
                        font-size: 10px !important;

                        &:hover {
                            color: red;
                        }
                    }
                }
            }
        }

        .single-medicine {
            .arrow {
                color: white !important;
                display: none;
            }

            &:hover {
                .arrow {
                    color: #000000b7 !important;
                    display: block;

                    &:hover {
                        color: #13AC81 !important;
                    }
                }

            }

        }

        .individual-medicine {
            position: relative;

            .medi-section {
                width: 90%;
                display: flex;

            }

            .medicine-action {
                text-align: center;
                width: 10%;
            }


        }
    }

    .medicine-list {

        width: 35%;
        border-left: .5px solid rgba(0, 0, 0, 0.463);



        .my-drug-list {
            .my-drug-title {
                background-color: rgba(168, 185, 179, 0.561);
            }

            input {
                margin-top: 0px;
                outline: none;
                border: .5px solid rgba(0, 0, 0, 0.21);
                font-size: 13px;
                width: 100%;
                padding-left: 4px;
            }

            div {
                cursor: pointer;
                color: $primary__500;

                &:hover {
                    color: $color__main;
                }
            }
        }



        input {
            outline: none;
            border: .5px solid rgba(0, 0, 0, 0.679);
            font-size: 13px;
            width: 75%;
            border-right: none;
            padding-left: 4px;

            &::placeholder {
                color: #13AC81;
            }
        }

        small {
            cursor: pointer;
            color: $primary__500;

            &:hover {
                color: $color__main;
            }
        }

        select {
            outline: none;
            border: .5px solid rgba(0, 0, 0, 0.21);
            font-size: 12px;
            width: 25%;
            // border-left: none;
        }
    }

    .same_org {
        background-color: rgba(255, 166, 0, 0.701);

        small {
            font-weight: 600 !important;
        }

        &:hover {
            background-color: rgba(255, 166, 0, 0.701) !important;
        }
    }



    .scroll-section {
        height: calc(60vh - 35px);
        overflow-y: scroll !important;


        .individual-medicine {
            border-bottom: .1px solid rgba(0, 0, 0, 0.46) !important;

            &:hover {
                background-color: rgba(151, 175, 175, 0.259);
                color: black !important;
            }
        }
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        display: block !important;
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: rgb(255, 255, 255);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #088aaa;
        border-radius: 10px;
    }
}