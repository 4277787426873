@import "../../assets/scss/partials/variables";

.appointment-page-container {
    margin-left: 71px !important;
    background-color: white;
    height: 96vh;
    overflow: hidden;
    td {
        font-size: 13px;
        padding-bottom: 1px;
    }

    .appointment-add {
        .appointment-add-button {
            background-color: $color__logo;
            color: white;
            font-size: 12px;
            font-weight: 500;
            // animation: breathe 2s ease-in-out infinite;
            padding: 2px 20px;


            .appointment-add-button-icon {
                animation: beat 2s ease-in-out infinite;

                @keyframes beat {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1);
                    }

                    100% {
                        transform: scale(1);
                    }
                }
            }
        }

        .appointment-search {
            // margin-top: 5px;
            width: 20%;
            outline: none;
            border: .5px solid rgba(0, 0, 0, 0.365) !important;
            border-radius: 4px;
            padding-left: 4px;
            font-size: 13px;
            padding: 4px 1px 4px 4px;

            &:hover {

                border: .5px solid $color__logo !important;
            }

            &:focus {
                border-color: #e3672d;
                border: .5px solid $color__logo !important;
            }

        }

        @keyframes breathe {
            0% {
                box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
            }

            70% {
                box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
            }

            100% {
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
        }
    }
}

.status-section {
    position: relative;
    cursor: pointer;

    .status {
        position: absolute;
        // height:40px;
        width: 5%;
        z-index: 1111111112;
        top: 100%;
        background-color: white;
        border: 1px solid $color__logo;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;

        .option {
            z-index: 9999;
            font-size: 12px;
            border-bottom: .5px solid $color__logo;
            margin: 0px;
            margin: auto;
            padding: auto;
            color: black;
            // background-color: red;

            &:hover {
                background-color: $color__logo;
                color: white;
            }
        }


    }
}

.disable-btn {
    pointer-events: none;
}

.present {
    color: #2d33e3;
}

.date-pick {
    position: absolute;
    border: none !important;
    outline: none !important;
    color: transparent !important;
    background-color: transparent !important;
}

.date-show {
    position: absolute;
    z-index: 2;
    padding: 0px 12px;
    border-radius: 2px;
    color: white;
    background-color: #0EAC81;
}

.appointment-details{
    p{
        font-size: 14px;
        font-weight: 600;
    }
    input{
        font-size: 12px;
        height: 20px !important;
        font-weight: 600;
        padding: 2px !important
    }
    select{
        font-size: 12px;
        height: 20px !important;
        font-weight: 600;
        padding: 2px !important
    }
}