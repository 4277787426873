@import"../../../assets/scss/partials/variables";

.individual-patient-section {
    display: flex;
    margin-top: 0px;


    .tab-option-section {
        width: 13%;
        height: 95vh;
        background-color: #F5F6FA;
        border-right: 1px solid #e5e9f2;
    }

    .tab-main-section {
        width: 55%;
        // border-left: .1px solid rgba(0, 0, 0, 0.235);
        background-color: white;

    }

    .individual-info-section {
        width: 32%;
        background-color: white;
        border-left: .5px solid rgba(0, 0, 0, 0.291);

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
            background-color: rgb(255, 255, 255) !important;
            border-radius: 10px !important;
            display: block !important;
        }

        ::-webkit-scrollbar {
            height: 6px !important;
            width: 6px !important;
            background-color: rgb(255, 255, 255) !important;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #088aaa !important;
            border-radius: 10px !important;
        }

    }
}