.full-page {
    height: 95vh;
    overflow-y: scroll;

    .upload-btn {
        font-size: 14px;
    }

    .ot-section {
        margin-bottom: 20px;

        .single-ot {
            border-radius: 6px;
            margin: 10px;

            .edit {
                border: 1px solid rgb(0, 0, 0);
                cursor: pointer;
                font-size: 12px !important;
            }

            .header {
                background-color: #0fac81;

                p {
                    font-size: 13px;
                    font-weight: 600;
                    color: white;
                    padding: 4px 10px;
                    margin: 0px;
                }
            }
        }
    }



}

// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: rgb(255, 255, 255);
//     border-radius: 10px;
//     display: block !important;
// }

// ::-webkit-scrollbar {
//     height: 6px;
//     width: 6px;
//     background-color: rgb(255, 255, 255);
// }

// ::-webkit-scrollbar-thumb {
//     background-color: #088aaa;
//     border-radius: 10px;
// }