@import '../../../../assets/scss/partials/variables';

.ot-note {
    input {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 13px;
        width: 100%;
        padding-left: 4px;
        height: 25px !important;
    }

    textarea {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 12px;
        width: 100%;
        padding: 0px 4px;
    }

    select {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 13px;
        width: 100%;
        padding: 2px 4px;
        height: 25px !important;
    }

    .submit-button {
        font-size: 13px;
        padding: 0px 10px;
        height: 20px;
        border-radius: 2px;
    }

    .cancel-button {
        background: $color__main;
        color: white;
        font-size: 13px;
        padding: 0px 10px;
        margin-left: 4px;
        border-radius: 2px;
    }
}

.ot-preview {

    .details {
        padding: 12px;

        .info-set {
            margin: 0px;

            .title {
                font-weight: 500 !important;
                // margin: 0px;
                width: 35%;
            }

            .value {
                font-weight: 600 !important;
                // margin: 0px;
                width: 65%;
            }

            p {
                font-size: 13px !important;
            }
        }

    }
}

.ot-preview-print {

    .details {
        padding: 12px;

        .info-set {
            margin: 0px;

            .title {
                font-weight: 400 !important;
                // margin: 0px;
                width: 32%;
            }

            .value {
                font-weight: 500 !important;
                // margin: 0px;
                width: 68%;
            }

            p {
                font-size: 13pt !important;
            }
        }

    }
}