@import "../../../assets/scss/partials/variables";

.full-history-section{
    overflow-y: scroll;
    height: 96vh;
    padding-left: 4px;
    
    label{
        font-size: 12px;
        padding: 0px;
        margin: 2px 0px;
        font-weight: 500 !important;
    }
    table{
        thead{
            tr{
                th{
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.667);
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 12px !important;
                    color: black;
                    font-weight: 500;
                }
            }
        }
    }
    .header{
        background-color: rgba(168, 185, 179, 0.561);
        // background-color: #F5F6FA;
        
        cursor: pointer;
    }
    .rotate-180{
        transform: rotate(180deg);
        padding-right: 16px !important;
    }
    .complaints-section{
        overflow-y: auto;
    }
    .vital-sign-section{
        overflow-x: auto;
    }

}

