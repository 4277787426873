@import "../../../assets/scss/partials/variables";

.full-page {
    .file-section {
        .individual-dates {

            .title {
                font-size: 13px;
                background-color: rgba(168, 185, 179, 0.561);
                width: 100%;
            }

            .file {
                margin: 6px 6px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                cursor: pointer;

                .type {
                    font-size: 11px;
                    color: white;
                    font-weight: 500;
                    background-color: $color__main;
                    text-align: center;
                    padding: 0px 25px;
                    margin: 8px 3px;

                }

                .description {
                    text-align: center;
                    font-size: 11px;
                    margin: 5px 3px;

                }
            }
        }
    }

    .upload-btn {
        font-size: 12px;
        background-color: $color__main;
        display: inline;
        padding: 1px 10px;
        border-radius: 1px;
        margin-left: 2px;
        color: white;
        cursor: pointer;
    }


}

.file-remove {
    position: absolute;
    font-size: 12px;
    top: 5%;
    left: 45%;

    &:hover {
        color: red;
    }
}

.preview {
    height: 90vh !important;

    .frame {
        text-align: center !important;
        width: 100%;
        height: 100%;
        margin: auto;

        body {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;

            img {
                width: 20px !important;
                margin: 0px auto !important;
                text-align: center;
                margin-left: 200px !important;
            }
        }

    }

}