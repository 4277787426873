.patient-vision-section {

    table {
        thead {
            tr {
                th {
                    font-size: 13px !important;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 13px !important;
                }

                font-size: 13px !important;
            }
        }
    }

    p {
        font-size: 13px;
    }
}

.patient-physical-exam-section {}

.patient-vital-section {}

.patient--complaint-section {}