@import "../../../assets/scss/partials/variables";

.complaint{
    .complaint-section {
        padding: 20px 20px;
        height: 92vh;
        overflow-y: scroll;
        overflow-x:hidden;
        textarea {
            font-size: 13px;
            color: black;
            outline: none !important;
            border: .5px solid $secondary__color;
            &:hover{
                color: black;
                border: .5px solid $color__main;
            }
            &:focus{
                color: black;
                border: .5px solid $color__main;
            }
        }
        .submit-button {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 30px;
        }
        
    
        table{
            thead{
                tr{
                    th{
                        font-size: 14px;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        font-size: 13px;
                    }
                }
            }
        }
        
        .template-reasonForVisit{
            position: relative;
            .layer{
                display: none;
                position: absolute;
            }
            &:hover{
                .layer{
                    display: inline-block;
                    position: absolute;
                    background: #0000004f;
                    width: 100%;
                    height: 100%;
                    top: 0%;
                    left: 0%;
                    .edit-add{
                        position: absolute;
                        width: 100%;
                        top: 40%;
                        left: 30%;
                    }
                }
            }
        }
        
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        display: block !important;
    }
    
    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: rgb(255, 255, 255);
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #088aaa;
        border-radius: 10px;
    }
}
