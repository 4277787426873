@import "../../../assets/scss/partials/variables";

.allergy-history-section {
    textArea {
        color: black;
    }

    .header {
        display: flex;
        padding: 4px 6px;

        p {
            font-size: 14px;
            padding: 0;
            margin: 0;
        }

        label {
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            padding: 0;
            cursor: pointer;
            color: black;
        }

        .button {
            cursor: pointer;
            font-weight: 600;
            color: white;
            background-color: $color__main;
            padding: 0px 6px;
            border-radius: 2px;
            margin-right: 10px;
            width: 70px;
            text-align: center;

            .spinner-border-sm {
                width: 0.7rem !important;
                height: 0.7rem !important;
                border-width: 0.1em !important;
            }
        }
    }

    .all-allergy {

        height: 96vh;
        overflow-y: scroll;

        .type-data {
            .title {
                background-color: #CED7D5;
                font-size: 13px;
                height: 20px;
                padding: 0px 5px;

            }

            table {
                thead {
                    tr {
                        th {
                            font-size: 12px;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 12px !important;

                            textarea {
                                font-size: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .rotate-180 {
        transform: rotate(180deg);
    }
}