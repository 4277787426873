.total-sell {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    // height: 45vh;
    padding-bottom: 20px;
    width: 100%;
    max-width: 400px;
    margin: 10px 0px;
    border-style: solid;
    border-color: rgba(224, 224, 224, 1);

    .total-sell-title {
        font-size: 14px;
        font-weight: 500;
    }

    .total-amount {
        p {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(85, 193, 254, 1);
        }
    }

    .sales-by-store {
        font-weight: 500;
    }

    .daraz-sale {
        background: rgba(226, 245, 255, 1);
        padding: 15px 10px 8px 5px;
        border-radius: 5px;

        p {
            padding-top: 5px;
            font-size: 13px;
            margin-bottom: 0px;
            padding-bottom: 0px;
            line-height: 4px;
        }
    }

    .sale {
        padding: 15px 10px 8px 5px;
        border-radius: 5px;

        p {
            padding-top: 5px;
            font-size: 13px;
            margin-bottom: 0px;
            padding-bottom: 0px;
            line-height: 4px;
        }
    }

    .daraz-color {
        background: rgba(226, 245, 255, 1);
    }

    .swap-color {
        background: rgba(255, 231, 220, 1);
    }

    .shopify-color {
        background: rgba(243, 255, 220, 1);
    }
    img{
        width: 40px;
    }

}

.order-status-chart{
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    // border-style: solid;
    border-color: rgba(224, 224, 224, 1);

}