@import "../scss/partials/_variables";


// main color font
.common-font {
    color: $color__logo;
}

.disable {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.4;
}

.text-secondary {
    color: #6c757d !important;
}

.common-font-color {
    color: $color__logo;
}

//button
.custom-common-button {
    color: #fff !important;
    background-color: $color__logo;
    cursor: pointer;
    border: none;
    margin-bottom: 0px;
    margin-top: 4px;
    font-size: 12px;
    padding: 4px 15px 3px 15px;
    border-radius: 4px;
}

//form
.custom-form {
    label {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.623);
    }

    .input {
        outline: none;
        border: .5px solid rgba(45, 44, 44, 0.35);
        height: 30px;
        color: black;

        &:hover {
            border: .5px solid $color__logo;
        }

        &:focus {
            border: .5px solid $color__logo;
        }

    }

    .individual-input-section {
        height: 70px;
        // background-color: red;
    }

}

.submit-button {
    background-color: $color__logo ;
    padding: 2px 20px;
    font-weight: 500;
    color: white;

    &:hover {
        color: aliceblue;
    }
}

// pagnation

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    font-weight: 700;
    background-color: $color__logo;
    border-color: $color__logo;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $color__logo;
    background-color: #fff;
    border: 1px solid #dee2e6;
}


//table
.card-body {
    padding-top: 4px;
}

table {
    tr {
        th {
            font-size: 12px;
            font-weight: 500;
            color: rgb(0, 0, 0);
            padding-top: 0px;
            margin-top: 0px;
        }
    }
    color: rgb(0, 0, 0) !important;
}

.table-link {
    color: black;
    padding-right: 30%;

    &:hover {
        color: #fff;
    }
}


// table
.active-row {

    // border-bottom: 1px solid $color__logo !important;
    // background-color: rgb(230, 212, 212)!important;
    .common-font {
        color: white !important;

    }

    a {
        color:#13ac25;
    }

    &:hover {
        color: $color__main !important;


    }

    color:#13ac25;
}

// .inactive-row {
//     &:hover {

//         // background-color: $color__logo !important;
//         color: $color__main !important;
//         cursor: auto;

//         .common-font {
//             color: $color__main !important;

//         }

//         a {
//             color: $color__main !important;
//         }
//     }
// }

//switch
.switch {
    position: relative;
    display: block;
    width: 30px;
    height: 14px;
    margin-top: 3px !important;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 1px;
    top: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.visit-btn {
    background-color: $color__main;
    color: white;
    font-size: 11px;
    font-weight: 500;
    padding: 1px 0px;
    cursor: pointer;
    width: 75%;
    border-radius: 2px;
    margin-bottom: 0px;
}


.success {
    background-color: #13AC81;
    height: 20px;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 30px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.cancel {
    background-color: #ac1313;
    height: 20px;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 20px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}