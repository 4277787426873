@import "../../../../assets/scss/partials/variables";

.patient-details-tab {
    padding-top: 10px;

    // border-bottom:1px solid $color__logo !important;
    .tab-option {
        cursor: pointer;

    }

    .tab-active-option {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: $color__logo;
        color: white;
    }
}