@import "../../../assets/scss/partials/variables";

.surgical-history-list {
   
    .surgical-history-search-section {
        // border: 1px solid red;
        padding: 2px 6px;
        display: flex;
        justify-content: space-between;

        label {
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            padding: 0;
            cursor: pointer;
            color: black;
        }

        input {
            font-size: 13px;
            padding: 0px 4px;
            width: 40%;
            outline: none;
        }
    }

    .surgical-scroll-section {
        height: calc(100vh - 60px);
        overflow-y: scroll;
        .surgical-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            .individual-surgery {
                padding: 6px;
                border: .5px solid rgba(0, 0, 0, 0.041);
                cursor: pointer;

                input {
                    color: $color__main !important;
                }

                label {
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }

            }
        }

        .extra-surgical-note {
            border: .5px solid rgba(0, 0, 0, 0.332);
            padding: 6px;
            margin-top: 10px;

            label {
                font-size: 14px;
                font-weight: 500;
            }

            textarea {
                font-size: 13px;
                color: black;
                outline: none;
            }

            .save-others-button {
                background-color: $color__main;
                font-size: 13px;
                color: white;
                font-weight: 600;
                padding: 2px 40px;
                text-align: center;
                margin-top: 10px;

                .spinner-border-sm {
                    width: 0.7rem !important;
                    height: 0.7rem !important;
                    border-width: 0.1em !important;
                }
            }
        }
    }
    .appointment-search{
        outline: none;
        width: 40%;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.356);
        border-radius: 4px;
        font-size: 13px;
    }

}