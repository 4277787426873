.appointment {
    .modal-content {
        background-color: #F3F6F9;
        position: relative;

        .close-button {
            position: absolute;
            cursor: pointer;
            color: red;
            font-weight: 700;
            font-size: 11;
            padding: 0px 5px 10px 5px;
            // border: .5px solid rgba(0, 0, 0, 0.297);
            // background-color: #F3F6F9;
            left: 93%;
            bottom: 95%;
            z-index: 5555;
        }

        left: 15%;
        width: 74% !important;

    }
}

.custom-modal {
    .custom-body {
        position: relative;

        .close-button {
            position: absolute;
            text-align: end;
            cursor: pointer;
            color: red;
            font-weight: 700;
            font-size: 11;
            padding: 0px 5px 10px 5px;
            // border: .5px solid rgba(0, 0, 0, 0.297); 
            // background-color: #F3F6F9;
            left: 93%;
            bottom: 95%;
            z-index: 1155555;
        }
    }
    z-index: 11155555;
}

.default {
    .custom-body {
        padding: 5px 20px !important;
        margin: 0px;
      
    }
}