.basic-info {
    font-size: 13px;

    input {
        font-size: 13px;
        height: 22px;
        color: black;
        font-weight: 600;
        outline: none;
    }

    textarea {
        font-size: 13px;
        color: black;
        font-weight: 600;
        outline: none;
        width: 100%;
        padding-left: 10px;
        border: .1px solid rgba(0, 0, 0, 0.475);
        border-radius: 4px;
    }

    label {
        font-size: 12px;
    }

    .save-button {
        background-color: #0EAC81;
        font-size: 12px !important;
        height: 20px !important;
        color: white;
        padding: 0px;
        font-weight: 600;
        width: 15% !important;
    }

    .reset-input {
        display: relative;

        .eye-icon {
            position: absolute;
            right: 1%;
            top: .5%;
        }
    }

    .base-color {
        color: #0EAC81;
    }

    .preview-image-info {
        margin-right: 20px;
        display: inline-block;
        border: 1px dashed;
        height: 60px;
        max-width: 120px;
    }
    
}