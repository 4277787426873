@import "../../../assets/scss/partials/variables";

.profile-name-component {
    margin-left: 4px;
    color: aqua;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    overflow: hidden;
    // border: 2px solid $color__logo;
    // cursor: pointer;
    background-color: $color__logo;
    color: white;

    .single-letter-profile-name {
        margin-left: 30%;
        margin-top: 12% !important;
        font-weight: 800;
        font-size: 16px;
    }

    .double-letter-profile-name {
        margin-left: 15%;
        margin-top: 16% !important;
        font-weight: 800;
        font-size: 14px;
    }
}