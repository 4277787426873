@import "../../../assets/scss/partials/variables";

.family-history-section {

    .family-history-section-header {
        display: flex;
        justify-content: space-between;

        .header {
            display: flex;
            padding: 4px 6px;

            p {
                font-size: 14px;
                padding: 0;
                margin: 0;
            }

            label {
                font-size: 13px;
                font-weight: 400;
                margin: 0;
                padding: 0;
                cursor: pointer;
                color: black;
            }

            .button {
                cursor: pointer;
                font-weight: 600;
                color: white;
                background-color: $color__main;
                padding: 0px 6px;
                border-radius: 2px;
                margin-right: 10px;
                width: 70px;
                text-align: center;

                .spinner-border-sm {
                    width: 0.7rem !important;
                    height: 0.7rem !important;
                    border-width: 0.1em !important;
                }
            }
        }

        .family-history-search {
            padding: 0px 6px;
            width: 30%;

            input {
                font-size: 13px;
                padding: 0px 4px;
                width: 100%;
                outline: none;
            }
        }
    }

    .scroll-section {
        height: calc(100vh - 60px);
        overflow-y: scroll;

        .individual-social-disease {
            display: grid;
            grid-template-columns: 1fr 1fr;
            font-size: 13px;
            padding: 4px 6px;
            border: .5px solid rgba(0, 0, 0, 0.041);

            p {
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
                color: black;
            }

            input {
                color: $color__main !important;
            }

            label {
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
                color: black;
            }

            .select-input {
                font-size: 12px;
                padding: 0px;
                border-radius: 4px;
                color: black !important;
                outline: none;
                border: .5px solid rgba(45, 44, 44, 0.35);
                cursor: auto !important;

                input {
                    color: black !important;
                }

                &:hover {
                    border: .5px solid $color__logo;

                }

                &:focus {
                    border: .5px solid $color__logo;
                }

            }

            svg {
                position: absolute;
                top: 1px;
                right: 2px;
                width: 15px;
            }

        }

        .other-family-history {
            padding: 10px 6px;

            label {
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
                color: black;
            }

        }

        textarea {
            font-size: 12px;
            color: black;
            outline: none;
            padding: 0px 4px;
        }
    }


}