@import "../../../assets/scss/partials/variables";

.patient-search-section {
    overflow: hidden;

    .search-input {
        margin-top: 0px;
        width: 60% !important;
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.365) !important;
        border-radius: 0px;
        height: 2.5%;
        font-size: 13px;
        padding: 4px 1px 4px 4px;

        &:hover {

            border: .5px solid $color__logo !important;
        }

        &:focus {
            border-color: #e3672d;
            border: .5px solid $color__logo !important;
        }

    }

    .search-result-section {
        margin-top: 10px;
        height: 60vh;
        overflow: scroll;
        background-color: white;
        width: 97%;

        .individual-patient {
            border-bottom: .5px solid rgba(0, 0, 0, 0.22);
            padding: 5px 7px 0px 7px;
            cursor: pointer;

            p {
                margin-bottom: 5px;
                font-size: 11px;
            }

            &:hover {
                background-color: rgba(240, 248, 255, 0.748);
            }
        }

        .active-patient {
            background-color: #0F5541 !important;
            font-weight: 600;
                td{
                    color: white !important;
                }
        }

        .no-patient-found {
            padding: 8px 4px 6px;
            margin-bottom: 5px;
            font-size: 13px;
        }
    }

    .custom-button {
        background-color: $color__logo;
        color: white;
        width: 97%;
        margin-top: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    input {
        margin-left: 2px !important;
        font-size: 13px !important;
        padding: 2px 4px !important;
        height: 20px !important;
    }

    select {
        outline: none !important;
        font-size: 13px !important;
        padding: 0px 4px !important;
    }

    table {
        thead {
            tr {
                th {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.834);
                    padding-top: 0px;
                    margin-top: 0px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgb(0, 0, 0);
                    padding-top: 0px;
                    margin-top: 0px;
                    cursor: pointer;
                }
            }
        }
    }
}


.patient-search-section-appointment {
    overflow: hidden;

    .search-input {
        margin-top: 0px;
        width: 98% !important;
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.365) !important;
        border-radius: 0px;
        height: 2.5%;
        font-size: 14px;
        padding: 4px 1px 4px 4px;

        &:hover {

            border: .5px solid $color__logo !important;
        }

        &:focus {
            border-color: #e3672d;
            border: .5px solid $color__logo !important;
        }

    }

    small {
        font-size: 12px !important;
    }

    .search-result-section {
        margin-top: 10px;
        height: 62vh;
        overflow: scroll;
        background-color: white;
        width: 97%;

        .individual-patient {
            border-bottom: .5px solid rgba(0, 0, 0, 0.22);
            padding: 5px 7px 0px 7px;
            cursor: pointer;

            p {
                margin-bottom: 5px;
                font-size: 11px;
            }

            &:hover {
                background-color: rgba(240, 248, 255, 0.748);
            }
        }

        .active-patient {
            background-color: #0fac82c2 !important;
            color: white;
            font-weight: 600;
        }

        .no-patient-found {
            padding: 8px 4px 6px;
            margin-bottom: 5px;
            font-size: 13px;
        }
    }

    .custom-button {
        background-color: $color__logo;
        color: white;
        width: 97%;
        margin-top: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    input {
        margin-left: 2px !important;
        font-size: 12px !important;
        padding: 2px 4px !important;
        height: 20px !important;
    }

    select {
        outline: none !important;
        font-size: 11px !important;
        padding: 0px 4px !important;
        height: 20px !important;
    }

    table {
        thead {
            tr {
                th {
                    font-size: 12px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.878);
                    padding-top: 0px;
                    margin-top: 0px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 11px;
                    font-weight: 500;
                    color: rgb(0, 0, 0);
                    padding-top: 0px;
                    margin-top: 0px;
                    cursor: pointer;
                }
            }
        }
    }

    button {
        background-color: #0fac82c2;
        cursor: pointer;
        color: white;

    }
}