.select-with-local-search {
    position: relative;

    label {
        margin-top: 4px;
        padding: 0px;
        margin-bottom: 0px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.631);
    }

    .icon-pos {
        position: absolute;
        font-size: 12px;
        top: 64%;
        right: 3%;
        color: rgba(0, 0, 0, 0.249);
        cursor: pointer;
    }
    .with-out-level-icon-pos{
        position: absolute;
        font-size: 12px;
        top: 37%;
        right: 3%;
        color: rgb(0, 0, 0);
        cursor: pointer;
    }

    input {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 13px;
        width: 100%;
        padding-left: 4px !important;
        display: block;
    }

    p {
        font-size: 12px;
        padding: 3px 4px;
        margin: 0px;
    }

    .rotate-180 {
        transform: rotate(180deg);
    }

    .option-section {
        z-index: 11111;
        position: absolute;
        width: 100%;
        border: .5px solid rgba(0, 0, 0, 0.21);
        border-top: none;
        background-color: white;
        max-height: 200px;
        overflow-y: scroll;
        box-shadow: 0 3px 12px 1px rgba(7, 82, 61, 0.15);
        padding-bottom: 4px;

        .single-option {
            cursor: pointer;
            border-bottom: .1px solid rgba(0, 0, 0, 0.606);
            padding: 2px;
            &:hover {
                background-color: azure;
            }
        }
    }
}