.vision-section {

    .title {
        background-color: #13AC81;
        color: white;
        border: .1px solid rgba(39, 38, 38, 0.453);
        text-align: center;
    }

    .vision {

        // border-right: .1px solid rgba(39, 38, 38, 0.453);
        p {
            font-size: 14px;
        }

        input {
            width: 100%;
        }
    }


    .spectacle {


        p {
            font-size: 14px;
        }
    }

    table {
        thead {
            tr {
                th {
                    font-size: 14px
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px
                }
            }
        }
    }

    input {
        outline: none;
        // border: 1px solid #13AC81;
        height: 22px;
        width: 100%;
    }

    .glasses {
        input {
            width: 13px;
        }
        label{
            font-size: 14px;
            font-weight: 400;
            color: black;
        }
    }

    .submit-button{
        font-size: 14px;
        padding: 0px;
    }
}