@import "../../../assets/scss/partials/variables";

.patient-create-form-section {
    background-color: white;

    display: flex;

    .input-section {
        width: 65%;
        padding: 20px 20px 10px 20px;
    }

    .details-section {
        width: 35%;
        height: 90vh;
        // background-color:#F3F6F9;
        border-left: .5px solid rgba(0, 0, 0, 0.272);
        padding: 20px 20px 10px 20px;

        p {
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.647);
        }
    }
}
