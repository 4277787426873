@import "../../../../assets/scss/partials/variables";

.creatable-search-select {
    position: relative;
    p {
        font-size: 13px;
        padding: 3px 4px;
        margin: 0px;
    }

    .input-color-border {
        outline: none !important;
        border: 0.8px solid rgba(0, 0, 0, 0.689) !important;
        font-size: 13px !important;
        width: 100% !important;
        padding-left: 4px !important;
    }

    .custom-color-border-1 {
        outline: none;
        border: 0.8px solid rgba(0, 0, 0, 0.689);
        font-size: 13px;
        width: 100%;
        padding-left: 4px;

        &::placeholder {
            color: #13ac81 !important;
        }
    }

    .option-section {
        z-index: 122 !important;
        position: absolute;
        width: 100%;
        border: 0.5px solid rgba(0, 0, 0, 0.21);
        border-top: none;
        background-color: white;
        box-shadow: 0 3px 12px 1px rgba(7, 82, 61, 0.15);

        .single-option {
            cursor: pointer;

            p {
                font-size: 13px;
                padding: 2px 4px;
            }

            border-bottom: 0.1px solid rgba(0, 0, 0, 0.416);

            &:hover {
                background-color: azure;
            }
        }
    }
    .fixed-height {
        height: 25vh;
        overflow-y: scroll;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        display: block !important;
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: rgb(255, 255, 255);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #088aaa;
        border-radius: 10px;
    }
}

.icd-11 {
    padding: 2px 4px;
    font-size: 13px;
    .found {
        color: #12ab81;
    }
}

.ant-select{
    height: 25px !important;
    border-radius: 0px !important;
    border: .1px solid black !important;
    outline: none !important;
}
.ant-select-selector {
    border-radius: 0px !important;
    font-size: 13px !important;

}

.ant-select-item-option-active {
    background-color: #13ac8178 !important;
    color: rgb(0, 0, 0) !important;
}

.ant-select-selector {
    border-color: inherit !important;
    box-shadow: none !important;
    border: .1px solid rgba(0, 0, 0, 0.396) !important;
  }
  
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active {
    border-color: #13ac8178 !important;;
    box-shadow: none !important;
    border: .1px solid rgba(0, 0, 0, 0.396) !important;
  }
  .ant-select-selection-placeholder {
    color: #13ac81da !important; /* Change this to your desired color */
    opacity: 1 !important; /* Ensure visibility */
  }
  

