.screen-msg {
    display: block;
    position: fixed;
    top: 20%;
    z-index: 999999;
    background-color: #0eac81;
    color: white;
    height: 60%;
    margin: 20px;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 600;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

.screen-msg-icon {
    width: 110px;
    padding: 0px;
    margin-top: 15%;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }

    80% {
        box-shadow: 0 0 0 20px #fbfbfb00;
    }
}

@media screen and (min-width: 900px) {
    .screen-msg {
        display: none;
    }
}