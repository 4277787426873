@import "../../../../assets/scss/partials/variables";

.vertical-tab-section {

    .vertical-tab-options {
        cursor: pointer;
        background-color: transparent;
        padding: 4px 2px 4px 2px;
        border-bottom: .1px solid rgba(0, 0, 0, 0.158);
        border-top: .1px solid rgba(0, 0, 0, 0.158);
        font-size: 14px !important;
        font-weight: 600;
        &:hover {
            color: $color__logo;
            // background-color: $color__logo;
        }
    }

}

.rotate-180 {
    transform: rotate(180deg);
}

.sub-options {
    cursor: pointer;
    margin: 0px;
    padding: 3px 2px 3px 2px !important;
    background-color: rgb(253, 253, 253);
    display: block;
    font-size: 13px;

    &:hover {
        color: $color__logo;
        // background-color: $color__logo;
    }
}
.active-option{
    color: $color__logo;
    font-size: 15px !important;
    // background-color: $color__logo !important;
}