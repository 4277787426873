.physical-exam-container {
    padding: 4px;
    height: 95vh;
    overflow-y: scroll;
    .header {
        border-bottom: 1px solid gray;
        margin-bottom: 6px;

        p {
            font-size: 14px !important;
            font-weight: 600;
            padding: 0px;
            margin: 0px;
        }

        .button {
            background-color: #13ac81;
            color: white;
            border-radius: 4px;
            cursor: pointer;
        }
    }

    .single-exam {
        padding: 6px;

        .delete {
            &:hover {
                // background-color: $color__main;
                color: red;
                cursor: pointer;
            }
        }

        .title {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            background-color: #13ac81;
            color: white;
        }

        label {
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }

        .default-button {
            background-color: #13ac81;
            color: white;
            font-size: 12px;
            margin: 0px;
            padding: 1px 6px;
            border-radius: 3px;
            cursor: pointer;
        }

        textarea {
            outline: none;
            font-size: 14px;
            padding: 4px;
            border-top-right-radius: 0px !important;
            border-top-left-radius: 0px !important;
        }
    }
}

.template-list-container {
    overflow-y: scroll;

    .title {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        background-color: #13ac81;
        color: white;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .list {
        .individual-list {
            padding: 0px 4px;

            p {
                font-size: 14px;
            }
        }
    }

    label {
        font-size: 13px;
        font-weight: 450;
        cursor: pointer;
    }

    textarea {
        outline: none;
        font-size: 13px;
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }
}

.new-template-section {
    .form-title {
        p {
            font-size: 13px;
            cursor: pointer;
        }
    }

    .custom-form {
        border-top: 1px solid gray;
        margin-top: 1.5px;
        padding-top: 10px;

        input {
            font-size: 13px;
            height: 25px;
            padding: 6px;
        }

        textarea {
            padding: 6px;
        }

        .submit-btn {
            background-color: #13ac81;
            color: white;
        }
    }
}

.new-physical-exam-section {
    .form-title {
        background-color: #13ac81;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        p {
            color: white;
        }
    }

    input {
        font-size: 13px;
        height: 25px;
        padding: 6px;
    }

    textarea {
        padding: 6px;
        font-size: 14px;
    }

    .submit-btn {
        margin-bottom: 10px;
        background-color: #13ac81;
        color: white;
    }
}

.physical-exam-print {
    .exams {
        .exam{
            border: .1px solid black;
            margin: 8px;
            padding: 8px;
        }
    }
}
