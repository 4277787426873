
.custom-nav-bar{
    background-color: #F5F6FA !important;
    select{
        font-size: 13px;
        color: black;
        font-weight: 700;
        padding: 3px;
        border: 1px solid #10AC81;
        outline: none;
    }
}