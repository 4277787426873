@import "../../../assets/scss/partials/variables";

.care-plan-section {
    padding: 20px 20px;
    textarea {
        font-size: 13px;
        color: black;
        outline: none !important;
        border: 0.5px solid $secondary__color;
        &:hover {
            color: black;
            border: 0.5px solid $color__main;
        }
        &:focus {
            color: black;
            border: 0.5px solid $color__main;
        }
    }
    .submit-button {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    table {
        thead {
            tr {
                th {
                    font-size: 14px;
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 13px;
                }
            }
        }
    }
}
.ql-editor {
    line-height: 1.2 !important; // You can change this value to fit your needs
}

.care-plan-template-section {
    height: 70vh !important;
    .name-section {
        width: 20%;
        border-right: 1px solid rgba(0, 0, 0, 0.185);
        box-shadow:
            rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 5px 5px 10px;
        border-radius: 6px;
        height: 68vh !important;
    }

    .prescription-section {
        width: 80%;
        box-shadow:
            rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 5px 2px 2px 2px;
        border-radius: 6px;
        padding: 4px;
        height: 68vh !important;
    }

    .template-name {
        button {
            background-color: $color__main;
            color: white;
            font-weight: 600;
            border-radius: 0px;
            font-size: 12px;
    
            &:hover {
                background-color: $color__main;
                color: white;
            }
        }
    
        input {
            width: 100% !important;
            outline: none !important;
            font-size: 12px !important;
            height: 10%;
            border-radius: 1px;
            border: 1px solid rgba(0, 0, 0, 0.3);
        }
    
        .names {
            height: 60vh;
            overflow-y: scroll;
    
            .individual-name {
                width: 100%;
                font-size: 12px;
                padding-left: 6px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.192);
                padding-top: 0px;
                margin-top: 0px;
    
                &:hover {
                    background-color: $color__main;
                    color: white;
                    // cursor: pointer;
                }
    
                .name {
    
                    &:hover {
                        // background-color: $color__main;
                        color: white;
                        cursor: pointer;
                    }
                }
                .delete{
                    &:hover {
                        // background-color: $color__main;
                        color: red;
                        cursor: pointer;
                    }
                }
                .update{
                    &:hover {
                        // background-color: $color__main;
                        color: rgb(0, 255, 64);
                        cursor: pointer;
                    }
                }
            }
    
           
        }
    
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: rgb(255, 255, 255);
            border-radius: 10px;
            display: block !important;
        }
    
        ::-webkit-scrollbar {
            height: 6px;
            width: 6px;
            background-color: rgb(255, 255, 255);
        }
    
        ::-webkit-scrollbar-thumb {
            background-color: #088aaa;
            border-radius: 10px;
        }
        label {
            font-size: 12px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.192);
            width: 100%;
            margin-bottom: 0px;
            font-weight: 400;
        }
    
        .active-template {
            color: $color__main;
            cursor: pointer;
        }
    
    
    }
    label{
        font-size: 14px;
    }
    input {
        outline: none;
        border: .5px solid rgba(0, 0, 0, 0.21);
        font-size: 13px;
        color: black;
        width: 100%;
        padding-left: 4px;
        height: 25px !important;
    }
}

