@import "../../../assets/scss/partials/variables";

.visit-history {
    height: calc( 100vh - 71px);
    overflow-y: scroll;

    .individual-visit-history {
        .history-header{
            background-color: rgba(132, 153, 153, 0.328);
        }
        label {
            font-size: 12px;
            padding: 1px 5px;
            width: 100%;
            margin-top: 0px;
            padding-top: 2px;
            margin-bottom: 0px;
        }

        span {
            font-size: 13px;
        }

        .title {
            font-size: 13px;
            padding: 2px 5px;
        }

        .content {
            font-size: 13px;
            padding: 2px 5px;
            font-weight: 500;
        }

        p {
            padding: 0px;
            margin: 0px;
        }
    }
}