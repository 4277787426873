@import "../../../../assets/scss/partials/variables";

.search-select {
    position: relative;

    input {
        outline: none;
        border: 0.5px solid rgba(73, 175, 47, 0.21);
        font-size: 13px;
        width: 100%;
        padding-left: 4px;
    }

    p {
        font-size: 12px;
        padding: 3px 4px;
        margin: 0px;
    }

    .option-section {
        z-index: 1111;
        position: absolute;
        width: 100%;
        border: 0.5px solid rgba(0, 0, 0, 0.21);
        border-top: none;
        background-color: white;
        box-shadow: 0 3px 12px 1px rgba(7, 82, 61, 0.15);
        .single-option {
            cursor: pointer;
            text-wrap: wrap;
            &:hover {
                background-color: azure;
            }
        }
    }
    .fixed-height {
        height: 35vh;
        overflow-y: scroll;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        display: block !important;
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: rgb(255, 255, 255);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #088aaa;
        border-radius: 10px;
    }
}
