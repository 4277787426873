@import "../../../assets/scss/partials/variables";

.individual-patient-section-header {
    margin-left: 71px;
    // height: 35px;
    padding: 3px 30px;
    background-color: $sidebar-background-color;

    p {
        font-size: 13px;
    }

    .back-section {
        width: 7%;
    }

    .patient-details-section {
        width: 88%;
        display: flex;

        p {
            font-size: 14px;
            font-weight: 500;
        }

        .complete-btn {
            background-color: #13AC81;
            height: 22px;
            border-radius: 3px;
            color: white;
            cursor: pointer;
        }
    }


}

.success {
    background-color: #13AC81;
    height: 20px;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 30px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.cancel {
    background-color: #ac1313;
    height: 20px;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 20px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.next-visit-modal {

    input {
        font-size: 13px;
        height: 25px;
        padding: 6px;
        color: black !important;
        outline: none !important;
        border: .2px solid black;
        border-radius: 4px;
    }

    .durations {
        background-color: #548175;
        border-radius: 4px;
        font-size: 13px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #0fac81;
        }
    }

    .active {
        background-color: #0fac81 !important;
        color: white !important;
    }

    textarea {
        font-size: 13px;
        padding: 6px;
        color: black !important;
    }
}