
.upload-design {
    cursor: pointer;
    border: 1px dashed #707070;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 5px;
    margin-right: 20px;
    height: 60px;
    width: auto;
}

.upload-design img {
    width: auto;
    height: auto;
    object-fit: contain;
    text-align: center;
}

.upload-design p {
    margin-top: 8px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #242424;
}

.preview-image {
    margin-right: 20px;
    display: inline-block;
    border: 1px dashed;
    position: relative;
    height: 60px;
}

.preview-image .remove-image {
    cursor: pointer;
    position: absolute;
    right: -8px;
    background: #3C69D8;
    /*background: #BD2131;*/
    height: 18px;
    width: 18px;
    font-size: 11px;
    text-align: center;
    border-radius: 50%;
    top: -10px;
    color: #fff;
    padding-top: 1px;
}

.preview-image-item {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}
