.create-appointment-section {
    height: 65vh;
    display: flex;
    .appointment-search-section {
        width: 50%;
        overflow-y: hidden;
        height: 100%;
        border-right: 1px solid rgba(46, 45, 45, 0.496);
    }

    .appointment-form-section {
        width: 50%;
        background-color: white;
        padding: 0px 10px 5px 5px;
        overflow-y: scroll;
        height: 100%;

    }
}