@import "../../../assets/scss/partials/variables";

.social-history-section {
    height: calc(100vh - 35px);
    overflow-y: scroll;

    .social-history-section-header {
        .header {
            display: flex;
            padding: 4px 6px;

            p {
                font-size: 14px;
                padding: 0;
                margin: 0;
            }

            .button {
                cursor: pointer;
                font-weight: 600;
                color: white;
                background-color: $color__main;
                padding: 0px 6px;
                border-radius: 2px;
                margin-right: 10px;
                width: 70px;
                text-align: center;
                .spinner-border-sm {
                    width: 0.7rem !important;
                    height: 0.7rem !important;
                    border-width: 0.1em !important;
                }
            }
        }
    }

    .individual-social-habit {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 13px;
        padding: 4px 6px;
        border: 0.5px solid rgba(0, 0, 0, 0.041);

        input {
            color: $color__main !important;
        }

        label {
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            color: black;
        }
        textarea {
            font-size: 12px;
            color: black;
            outline: none;
            padding: 0px 4px;
            &:focus {
                color: black;
            }
        }
    }

    .other-social-history {
        padding: 10px 6px;

        label {
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            color: black;
        }
    }

    textarea {
        font-size: 12px;
        color: black;
        outline: none;
        padding: 0px 4px;
        &:focus {
            color: black;
        }
    }
    .social-history-search {
        width: 40%;
        input {
            outline: none;
            width: 100%;
            height: 25px;
            border: 1px solid rgba(0, 0, 0, 0.356);
            border-radius: 4px;
            font-size: 13px;
        }
    }
}
