@import "../../../../assets/scss/partials/variables";

.vital-signs {
    display: flex;
    // margin-top: 25px;
    // height: 95vh;

    .vital-signs-form {
        padding-left: 10px;
        padding-right: 10px;
        width: 60%;
        border-right: .5px dotted rgba(0, 0, 0, 0.299);

        .title {
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            // margin-bottom: 10%;
            border-bottom: .5px solid rgba(0, 0, 0, 0.197);
            // background-color: $tab-background-color;
            color: $color__logo;
        }

        label {
            font-size: 12px;
        }

        p {
            font-size: 9px;
            color: rgba(0, 0, 0, 0.663);
            padding: 0px;
            padding-right: 5px;
        }

        input {
            outline: none;
            border: .5px solid rgba(45, 44, 44, 0.35);
            height: 25px;
            font-size: 13px;

            &:hover {
                border: .5px solid $color__logo;
            }

            &:focus {
                border: .5px solid $color__logo;
            }

        }

        textarea {
            outline: none;
            border: .5px solid rgba(45, 44, 44, 0.35);
            font-size: 13px;
            padding: 4px;

            &:hover {
                border: .5px solid $color__logo;
            }

            &:focus {
                border: .5px solid $color__logo;
            }
        }

        .addInfo-button-position {
            margin-top: 14%;
        }
    }

    .vital-signs-details {
        width: 40%;
        padding: 0px 5px 0px 5px;

        p {
            font-size: 13px
        }
    }

}