@import "../../assets/scss/partials/variables";

.template-name {
    button {
        background-color: $color__main;
        color: white;
        font-weight: 600;
        border-radius: 0px;
        font-size: 12px;

        &:hover {
            background-color: $color__main;
            color: white;
        }
    }

    input {
        width: 100% !important;
        outline: none !important;
        font-size: 12px !important;
        height: 10%;
        border-radius: 1px;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }

    .names {
        height: 90vh;
        overflow-y: scroll;

        .individual-name {
            width: 100%;
            font-size: 12px;
            padding-left: 6px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.192);
            padding-top: 0px;
            margin-top: 0px;

            &:hover {
                background-color: $color__main;
                color: white;
                // cursor: pointer;
            }

            .name {

                &:hover {
                    // background-color: $color__main;
                    color: white;
                    cursor: pointer;
                }
            }
            .delete{
                &:hover {
                    // background-color: $color__main;
                    color: red;
                    cursor: pointer;
                }
            }
            .update{
                &:hover {
                    // background-color: $color__main;
                    color: rgb(0, 255, 64);
                    cursor: pointer;
                }
            }
        }

       
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        display: block !important;
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: rgb(255, 255, 255);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #088aaa;
        border-radius: 10px;
    }
    label {
        font-size: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.192);
        width: 100%;
        margin-bottom: 0px;
        font-weight: 400;
    }

    .active-template {
        color: $color__main;
        cursor: pointer;
    }


}

.create-template {
    label {
        padding: 0px !important;
        margin: 0px !important;
        font-size: 13px !important;
        font-weight: 500;
    }


    input {
        width: 100% !important;
        outline: none !important;
        font-size: 12px !important;
        border-radius: 1px;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }

    button {
        font-size: 12px;
        background-color: $color__main;
        color: white;

        &:hover {
            background-color: $color__main;
            color: white;
        }
    }
}